import {RESET_START_DATE, SET_MIN_DATE, SET_START_DATE} from '../actions/tripOrganisationActions';

const initialState = { // Define the initial state
    minDateSet: null, // Or whatever your default value should be
    startDate: null
};

const tripOrganisationReducer = (state = initialState, action) => { // Provide default state value
    switch (action.type) {
        case SET_MIN_DATE:
            return {
                ...state,
                minDateSet: action.payload,
            };
        case SET_START_DATE:
            return {
                ...state,
                startDate: action.payload,
            };
        case RESET_START_DATE:
            return {
                ...state,
                startDate: null,
            };
        default:
            return state;
    }
};

export default tripOrganisationReducer;