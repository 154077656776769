import React from "react";
import "./styles.css";

const DatepickerDayButton = ({ day, isActive, isInRange, onClick, isDisabled }) => {

  return (
      <div className={`datepicker-day ${isActive ? 'active' : ''} ${isInRange ? 'in-range' :''} ${isDisabled ? 'disabled' : ''}`} onClick={onClick}>
          <div className={`datepicker-day-inner ${isActive ? 'translate' : ''} ${isInRange ? 'translate' :''} ${isDisabled ? 'disabled' : ''}`}>
              <div className={`day-number ${isDisabled ? 'disabled' : ''}`}>{day}</div>
          </div>

      </div>
  );
};

export default DatepickerDayButton;
