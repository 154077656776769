import React from 'react';
import './styles.css';
import'../../commonStyles.css'
import PlusButton from "../tripOrganisation/buttons/plusButton";
import MinusButton from "../tripOrganisation/buttons/minusButton";
import AdultsButton from "../tripOrganisation/buttons/adultsButton";

function Counter({value, onChange}) {
    const increment = () => {
        onChange(value + 1); // Ensure this calls the onChange function correctly
    };

    const decrement = () => {
        if (value > 0) {
            onChange(value - 1); // Ensure this calls the onChange function correctly
        }
    };

    return (
        <div className="counter">
            <div className="controls">
                <MinusButton onClick={decrement}/>
                <AdultsButton/>
                <PlusButton onClick={increment}/>
            </div>
            <div className="adult-counter-info rounded-right-button">
                <span className="adult-counter-info-inner rounded-right-button">
                    <div className={`adult-counter-value highlight`}>
                        {value}
                    </div>
                </span>
            </div>
        </div>
    );
}

export default Counter;
