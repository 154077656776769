import {calculateDurationInMinutes} from "../../../../utils/calculationFunctions";
import React from 'react'; // Import React for JSX

const TransferInfo = ({transferDetails}) => {
    let transferType = transferDetails?.info1?.detailsGroup1[0]?.segments[0]?.transferType || "";
    let startTime = transferDetails?.info1?.detailsGroup1[0]?.segments[0]?.start?.dateTime || "";
    let endTime = transferDetails?.info1?.detailsGroup1[0]?.segments[0]?.end?.dateTime || "";
    if (transferDetails && startTime !== "") {
        console.log("ehheehrhehreasas", transferDetails.info1.detailsGroup1[0].segments[0]);
        return <p className="monitor-text"> {transferType} -
            {calculateDurationInMinutes(startTime, endTime)}</p>;
    } else {
        return <p className="monitor-text"> No available transfers</p>;
    }
}

export default TransferInfo;