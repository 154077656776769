import React from 'react';
import {useLocation} from 'react-router-dom';
import OffersCarousel from './carousel/carousel';

const OffersPage = () => {
    const location = useLocation();
    const {offers = [], isLoading = false} = location.state || {};

    return (
        <div>
            <OffersCarousel offers={offers} isLoading={isLoading}/>

        </div>
    );
};

export default OffersPage;
