// src/api/getRequest.js
const AsynchronousGetRequest = async (path) => {
    let requestUrl;

    if (process.env.REACT_APP_WORKMODE === 'dev') {
        requestUrl = `http://${process.env.REACT_APP_URL}:${process.env.REACT_APP_DJANGO_PORT}/${path}`;
    } else {
        // Prepend a forward slash to the path to ensure it works as an absolute URL path
        requestUrl = `/${path.replace(/^\/+/, '')}`; // Ensures no leading slashes if already present
    }

    try {
        const response = await fetch(requestUrl, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (response.ok) {
            const responseData = await response.json();
            return responseData; // Return the data if the request is successful
        } else {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
    } catch (error) {
        console.error("Fetching data failed: ", error);
        throw error; // Rethrow the error to handle it in the component
    }
};

export default AsynchronousGetRequest;
