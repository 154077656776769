import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {updateOfferSelection} from '../../../store/actions/offerActions';
import {
    countFirstLevelChildren,
    processTransferFromAccommodation,
    processTransferToAccommodation
} from "../../utils/dataProcessingFunctions";
import SelectionButton from "../carousel/detailsPage/buttons/selectionButton";
import TransferOffer from "./TransferOffer";
import {splitPriceAndCurrency} from "../../utils/calculationFunctions";


const TransfersPage = ({transferData, offers, destinationAirport}) => {

    processTransferToAccommodation(transferData, offers.offers[1], 0);
    processTransferFromAccommodation(transferData, offers.offers[3], 0);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const REDUX_INDEX_TRANSFER_TO_ACCOMMODATION = 2;
    const REDUX_INDEX_TRANSFER_FROM_ACCOMMODATION = 4;

    const selectedOptionTransferToIndexFromRedux = useSelector((state) => {
        const offerDetails = state.offer.selectedOffers || {};
        return offerDetails[REDUX_INDEX_TRANSFER_TO_ACCOMMODATION];
    });

    const selectedOptionTransferFromIndexFromRedux = useSelector((state) => {
        const offerDetails = state.offer.selectedOffers || {};
        return offerDetails[REDUX_INDEX_TRANSFER_FROM_ACCOMMODATION];
    });

    const [selectedOptionTransferToIndex, setSelectedOptionTransferToIndex] = useState(selectedOptionTransferToIndexFromRedux);
    const [selectedOptionTransferFromIndex, setSelectedOptionTransferFromIndex] = useState(selectedOptionTransferFromIndexFromRedux);

    useEffect(() => {
        setSelectedOptionTransferToIndex(selectedOptionTransferToIndexFromRedux);
    }, [selectedOptionTransferToIndexFromRedux]);

    useEffect(() => {
        setSelectedOptionTransferFromIndex(selectedOptionTransferFromIndexFromRedux);
    }, [selectedOptionTransferFromIndexFromRedux]);


    const handleOptionTransferToClick = (index) => {

        if (selectedOptionTransferToIndex === index) {
            // If the same option is clicked, unselect it
            setSelectedOptionTransferToIndex(null);
            dispatch(updateOfferSelection(REDUX_INDEX_TRANSFER_TO_ACCOMMODATION, null)); // Set to null in Redux
        } else {
            // Select a new option
            setSelectedOptionTransferToIndex(index);
            dispatch(updateOfferSelection(REDUX_INDEX_TRANSFER_TO_ACCOMMODATION, index));
        }

    };

    const handleOptionTransferFromClick = (index) => {

        if (selectedOptionTransferFromIndex === index) {
            // If the same option is clicked, unselect it
            setSelectedOptionTransferFromIndex(null);
            dispatch(updateOfferSelection(REDUX_INDEX_TRANSFER_FROM_ACCOMMODATION, null)); // Set to null in Redux
        } else {
            // Select a new option
            setSelectedOptionTransferFromIndex(index);
            dispatch(updateOfferSelection(REDUX_INDEX_TRANSFER_FROM_ACCOMMODATION, index));
        }
    };

    const handleCombinedClick = (index) => {
    handleOptionTransferToClick(index);
    handleOptionTransferFromClick(index);
};

    const getClassName = (numberOfButtons, buttonOrderNumber) => {
        switch (numberOfButtons) {
            case 1:
                return buttonOrderNumber === 0 ? "single-button rounded-bottom-button" : "";
            case 2:
                return buttonOrderNumber === 0 ? "one-of-two left" : "one-of-two right";
            case 3:
                return buttonOrderNumber === 0 ? "left" :
                    buttonOrderNumber === 1 ? "central" : "right";
            default:
                return "";
        }
    };

    const renderOptions = (selectionBox, selectionBoxBack) => {
        return Object.keys(selectionBox).map((key, index) => {
            const option = selectionBox[key];
            const optionBack = selectionBoxBack[key];
            const isSelected = index === selectedOptionTransferToIndex;
            const buttonCount = countFirstLevelChildren(selectionBox);

            const departurePriceArray = splitPriceAndCurrency(option.price);
            const returnPriceArray = splitPriceAndCurrency(optionBack.price);

            const totalPrice = (Number(departurePriceArray[0]) + Number(returnPriceArray[0])).toFixed(2);
            let finalPrice;
            finalPrice = totalPrice + departurePriceArray[1];
            return (
                <SelectionButton
                    key={key}
                    price={finalPrice}
                    onClick={() => handleCombinedClick(index)}
                    isSelected={isSelected}
                    className={getClassName(buttonCount, index)}
                />
            );

        });
    };

    return (
        <div className="details-wrapper">
            <div className="overlay-content">
                <h2 className="page-title">Transfers</h2>
                <TransferOffer details={offers.offers[1].info1.detailsGroup1[0]}
                               detailsBack={offers.offers[3].info1.detailsGroup1[0]}
                destinationAirport = {destinationAirport}/>
                <div
                    className="selection-options">{renderOptions(offers.offers[1].info1['selection-box'], offers.offers[3].info1['selection-box'])}
                </div>

            </div>
            <div className="overlay-content">
                {/*<h2 className="page-title">Transfer to accommodation</h2>*/}
                {/*<h2 className="page-title">Transfer to airport</h2>*/}
            </div>

        </div>
    );
};

export default TransfersPage;
