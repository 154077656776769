import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PlaneIconLanding from '../../../../images/icons/plane-landing.svg';
import PlaneIconTakeOff from '../../../../images/icons/plane-takeoff.svg';
import TaxiIcon from '../../../../images/icons/car-taxi-front.svg';
import HotelIcon from '../../../../images/icons/bed-double.svg';
import FlightInfo from './itemInfo/flightInfo';
import TransferInfo from './itemInfo/transferInfo';
import HotelInfo from './itemInfo/hotelInfo';
import './styles.css';
import { modifyDateTimeString } from '../../../utils/calculationFunctions';

const ExpandableItem = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [transformedOffers, setTransformedOffers] = useState([]);
    const offerInRedux = useSelector((state) => state.offer.offerInRedux || []);
    const selectedOffers = useSelector((state) => state.offer.selectedOffers);

    useEffect(() => {
        if (offerInRedux.offers) {
            // Transform offers only when offerInRedux changes
            const transformed = createNewOffer([...offerInRedux.offers]); // Ensure no mutation
            setTransformedOffers(transformed);
        }

        // Add a slight delay to simulate loading
        const timeoutId = setTimeout(() => {
            setIsLoading(false);
        }, 100);

        return () => clearTimeout(timeoutId);
    }, [offerInRedux]);

    function createNewOffer(data) {
        const originalOffer = data.find((offer) => offer.id === 1);
        if (!originalOffer) {
            console.error("Offer with id:1 not found.");
            return data;
        }

        const detailsGroup2 = originalOffer.info1['detailsGroup2'] || [];
        const selectionBox2 = originalOffer.info1['selection-box-2'] || {};

        const newOffer = {
            id: 5,
            info1: {
                'detailsGroup1': detailsGroup2,
                'selection-box': selectionBox2,
            },
        };

        // Ensure no duplicates
        if (!data.find((offer) => offer.id === 5)) {
            data.push(newOffer);
        }

        return data;
    }

    const getIcon = (id) => {
        const icons = [PlaneIconTakeOff, TaxiIcon, HotelIcon, TaxiIcon, PlaneIconLanding];
        return icons[id - 1] || PlaneIconTakeOff;
    };

    const getLabelText = (id) => {
        switch (id) {
            case 1:
                return 'FLIGHT CHECK-IN';
            case 2:
                return 'TRANSFER TO ACCOMMODATION';
            case 4:
                return 'TRANSFER TO AIRPORT';
            case 3:
                return 'ACCOMMODATION CHECK-IN';
            case 5:
                return 'FLIGHT CHECK-IN';
            default:
                return 'PICOTRIP';
        }
    };

    const getHighlightedInfo = (id) => {
        return true; // Simplified for brevity
    };

    const getLabelTime = (id, offer) => {
        const item = offer.find((data) => data.id === id);
        if (!item) {
            console.error(`Item with id ${id} not found in the offer.`);
            return '';
        }

        const selectedIndex = selectedOffers?.[id] || 0;

        let displayTime = '';
        switch (id) {
            case 1:
            case 5:
                displayTime = item.info1?.detailsGroup1[selectedIndex]?.segments[0]?.departure?.at || 'No departure time available';
                return modifyDateTimeString(displayTime);
            case 2:
            case 4:
                displayTime = item.info1?.['selection-box']?.[`option${selectedIndex + 1}`]?.time || 'No dateTime available';
                return modifyDateTimeString(displayTime);
            case 3:
                displayTime = item.info1?.detailsGroup1[selectedIndex]?.segments[0]?.checkInTime || 'No dateTime available';
                return displayTime;
            default:
                return '';
        }
    };

    const renderItemContent = (item) => {
        const details = transformedOffers[item.id - 1];

        if (!details) {
            return <span>Loading...</span>;
        }

        const selectedIndex = selectedOffers?.[item.id] || 0;

        switch (item.id) {
            case 1:
            case 5:
                const firstSegment = details.info1.detailsGroup1[selectedIndex].segments[0] || "No flight data available";
                const lastSegment = details.info1.detailsGroup1[selectedIndex].segments[details.info1.detailsGroup1[0].segments.length - 1] || "No flight data available";
                const totalDuration = details.info1.detailsGroup1[selectedIndex].totalDuration || "No duration info";

                return (
                    <FlightInfo
                        flightDetails={{
                            departure: firstSegment.departure || "No departure info",
                            arrival: lastSegment.arrival || "No arrival info",
                            segments: details.info1.detailsGroup1[selectedIndex].segments || "No flight info",
                            totalDuration: totalDuration,
                            item: item.id,
                        }}
                    />
                );
            case 2:
            case 4:
                return <TransferInfo transferDetails={details} isEnabled={getHighlightedInfo(item.id)} />;
            case 3:
                return <HotelInfo hotelDetails={details} index={selectedIndex} />;
            default:
                return <span>{item.title}</span>;
        }
    };

    return (
        <div className="timeline-container">
            {isLoading ? (
                <p className="loading-text">Loading data...</p>
            ) : (
                <>
                    {transformedOffers.map((item) => (
                        <React.Fragment key={item.id}>
                            <div className="timeline-wrapper">
                                <div className="item-label">
                                    <p className="label-description">{getLabelText(item.id)}</p>
                                    <p className="label-time">{getLabelTime(item.id, transformedOffers)}</p>
                                </div>
                                <div className="timeline-item rounded-button">
                                    <div className="timeline-icon rounded-left-button">
                                        <div className="timeline-icon-inner rounded-left-button">
                                            <img src={getIcon(item.id)} alt="" />
                                        </div>
                                    </div>
                                    <div className="event-container">
                                        <div className="event-container-inner">
                                            <div className={`event-info ${getHighlightedInfo(item.id) ? "highlight" : ""}`}>
                                                {renderItemContent(item)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    ))}
                </>
            )}
        </div>
    );
};

export default ExpandableItem;
