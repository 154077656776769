import React from 'react';
import './styles.css';
import NextButtonOffers from "../tripOrganisation/buttons/nextButtonOffers";
import OfferSelectionMonitor from "../offersPage/buttons/offerSelectionMonitor";

const OffersNavigation = ({onNextClick, step, price, flightsSelected, accommodationSelected}) => {
    return (
        <div className="navigation-wrapper">
            <OfferSelectionMonitor step={step} price={price} />
            <NextButtonOffers onClick={onNextClick} flightsSelected={flightsSelected} accommodationSelected={accommodationSelected} step={step}/>
        </div>
    );
};

export default OffersNavigation;