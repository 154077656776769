import React from 'react';
import '../carousel/detailsPage/details/styles.css';

const HotelAmenitiesPage = ({ details }) => {

    // Extract amenity_groups from the first segment
    const amenityGroups = details?.segments[0]?.amenity_groups || "amenity groups not available";

    return (
        <>
            <div className="amenities-wrapper">
                {/* Render amenities if available */}
                {amenityGroups !== "amenity groups not available" && (
                    <div className="amenities-content">
                        {amenityGroups.map((group, index) => (
                            <div key={index} className="amenities-group">
                                <h2 className="amenities-title">{group.group_name}</h2>
                                <ul className="amenities-list">
                                    {group.amenities.map((amenity, idx) => (
                                        <li key={idx} className="amenity-item">{amenity}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
};

export default HotelAmenitiesPage;