import React from 'react';
import '../carousel/detailsPage/details/styles.css';
import './styles.css';
import {ReactComponent as PlaneIconLanding} from '../../../images/icons/plane-landing.svg';
import {ReactComponent as PlaneIconTakeOff} from '../../../images/icons/plane-takeoff.svg';

const FlightOffer = ({details, detailsBack, totalDuration, totalDurationBack}) => {

    const formatDuration = (duration) => {
        const matches = duration.match(/PT(\d+H)?(\d+M)?/);
        const hours = matches?.[1]?.slice(0, -1) || '0';
        const minutes = matches?.[2]?.slice(0, -1) || '0';
        return `${hours}h ${minutes}m`;
    };

    const renderFlightDetails = (segments, totalDuration) => {
        // Check if segments exist and have at least one element
        if (!segments || segments.length === 0) return null;

        // Get flight info from first and last segments
        const firstSegment = segments[0] || {};
        const lastSegment = segments[segments.length - 1] || {};
        const departureAirport = firstSegment.departure?.iataCode || '';
        const departureTime = firstSegment.departure?.at?.slice(11, 16) || '';
        const arrivalAirport = lastSegment.arrival?.iataCode || '';
        const arrivalTime = lastSegment.arrival?.at?.slice(11, 16) || '';

        // Get layover info (excluding first and last segments)
        const layoverAirports = segments
            .slice(1, -1)
            .map((segment) => segment.arrival?.iataCode)
            .join(', ') || '';

        return (
            <React.Fragment>
                <div className="flight-details-container">
                    {/* Departure Airport and Time */}
                    <div className="flight-details-departure">
                        <span className="airport-text">{departureAirport}</span>
                        <span className="departure-time">
                             <PlaneIconTakeOff/> {departureTime}
                        </span>
                    </div>

                    {/* Duration and Layover Airports */}
                    <div className="flight-duration-container">
                        <p className="duration-text">
                            &lt;- {formatDuration(totalDuration)} -&gt;
                        </p>
                        {layoverAirports && (
                            <div className="monitor-layover-info">
                                {layoverAirports}
                            </div>
                        )}
                    </div>

                    {/* Arrival Airport and Time */}
                    <div className="flight-details-arrival">
                        <span className="airport-text">{arrivalAirport}</span>
                        <span className="arrival-time">
                            <PlaneIconLanding/> {arrivalTime}
                        </span>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    return (
        <div className="offer-details">
            <div className="container-info flight-container">
                <div className="flight-info-inner">
                    <div className="flight-monitor highlight">
                        {!details && (
                            <>
                                <div className="selection-title">
                                    SELECT YOUR FLIGHT
                                </div>
                                <div className="separator-line"></div>
                                <div className="item-selection-container">
                                </div>
                            </>
                        )}
                            <>
                                <div className="flight-section-title" style={{ visibility: details ? 'visible' : 'hidden' }}>
                                    FLIGHT TO DESTINATION
                                </div>
                                <div className="separator-line" style={{ visibility: details ? 'visible' : 'hidden' }}></div>
                            </>
                        {/* Render details (Flight To) */}
                        {details && renderFlightDetails(details.segments, totalDuration)}
                        {details && detailsBack && (
                            <>
                                <div className="flight-section-title">
                                    FLIGHT BACK
                                </div>
                                <div className="separator-line"></div>
                            </>
                        )}

                        {/* Render detailsBack (Flight Back) */}
                        {detailsBack && renderFlightDetails(detailsBack.segments, totalDurationBack)}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default FlightOffer;