import React, { useState, useEffect, useMemo } from "react";
import { useSwipeable } from "react-swipeable"; // Importing the swipeable library
import "./styles.css";
import DatepickerDayButton from "../tripOrganisation/buttons/keyboardButton";
import DatepickerDoneButton from "../tripOrganisation/buttons/datepickerDoneButton";
import LeftMonthButton from "../tripOrganisation/buttons/leftMonthButton";
import MonthButton from "../tripOrganisation/buttons/monthButton";
import RightMonthButton from "../tripOrganisation/buttons/rightMonthButton";
import YearButton from "../tripOrganisation/buttons/yearButton";
import { useDispatch, useSelector } from "react-redux";
import {
  resetStartDate,
  setMinimumSelectableDate,
  setStartDateInDatepicker,
} from "../../store/actions/tripOrganisationActions";

const months = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December",
];

const dayNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

const CustomCalendar = ({ isOpen, onClose, onDateSelect, selectedRange }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [tempStartDate, setTempStartDate] = useState(null);
  const [tempEndDate, setTempEndDate] = useState(null);
  const [error, setError] = useState(null);
  const [minSelectableDate, setMinSelectableDate] = useState(null);
  const [isAutoNavigating, setIsAutoNavigating] = useState(false);

  const minDateFromRedux = useSelector((state) => state.tripOrganisation.minDateSet);
  const startDateFromRedux = useSelector((state) => state.tripOrganisation.startDate);

  const dispatch = useDispatch();

  useEffect(() => {
    setMinSelectableDate(minDateFromRedux);
  }, [minDateFromRedux]);

  useEffect(() => {
    setTempStartDate(startDateFromRedux);
  }, [startDateFromRedux]);

  useEffect(() => {
    if (isOpen && !hasSelectableDaysInCurrentMonth()) {
      setIsAutoNavigating(true);
      handleNextMonth();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isAutoNavigating && hasSelectableDaysInCurrentMonth()) {
      setIsAutoNavigating(false);
    }
  }, [currentDate]);

  useEffect(() => {
    if (selectedRange) {
      setTempStartDate(selectedRange.start);
      setTempEndDate(selectedRange.end);
    } else {
      setTempStartDate(startDateFromRedux || null);
      setTempEndDate(null);
    }
  }, [selectedRange]);

  const getDaysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();

  const getNumberOfRows = () => {
    const daysInMonth = getDaysInMonth(currentDate.getFullYear(), currentDate.getMonth());
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();
    return Math.ceil((firstDayOfMonth + daysInMonth) / 7);
  };

  const handleDayClick = (day) => {
    const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
    setError(null);

    if (!tempStartDate) {
      setTempStartDate(date);
      setMinSelectableDate(date);
      dispatch(setMinimumSelectableDate(date));
      dispatch(setStartDateInDatepicker(date));
      onDateSelect(date);
    } else if (!tempEndDate) {
      if (date > tempStartDate) {
        setTempEndDate(date);
        const today = new Date();
        const newMin = new Date(today.setDate(today.getDate() + 7));
        setMinSelectableDate(newMin);
        dispatch(setMinimumSelectableDate(newMin));
        onDateSelect(date);
      } else {
        setError("End date should be after the start date");
      }
    } else {
      setTempStartDate(date);
      dispatch(resetStartDate());
      setTempEndDate(null);
      const today = new Date();
      const newMin = new Date(today.setDate(today.getDate() + 7));
      setMinSelectableDate(newMin);
      dispatch(setMinimumSelectableDate(newMin));
      onDateSelect(date);
    }
  };

  const handlePreviousMonth = () => {
    if (isAutoNavigating) return;
    const newMonth = currentDate.getMonth() - 1;
    const newYear = currentDate.getFullYear() - (newMonth < 0 ? 1 : 0);
    setCurrentDate(new Date(newYear, (newMonth + 12) % 12, 1));
  };

  const handleNextMonth = () => {
    const newMonth = currentDate.getMonth() + 1;
    const newYear = currentDate.getFullYear() + (newMonth > 11 ? 1 : 0);
    setCurrentDate(new Date(newYear, newMonth % 12, 1));
  };

  const isDayActive = (day) => {
    if (!tempStartDate) return false;
    const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
    return date.getTime() === tempStartDate.getTime();
  };

  const isDaySelectable = (day) => {
    const today = new Date();
    const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
    return date > new Date(today.setDate(today.getDate() + 7)) && date >= minSelectableDate;
  };

  const isDayInRange = (day) => {
    if (!tempStartDate || !tempEndDate) return false;
    const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
    return date >= tempStartDate && date <= tempEndDate;
  };

  const hasSelectableDaysInCurrentMonth = () => {
    const daysInMonth = getDaysInMonth(currentDate.getFullYear(), currentDate.getMonth());
    for (let day = 1; day <= daysInMonth; day++) {
      if (isDaySelectable(day)) {
        return true;
      }
    }
    return false;
  };

  const renderDays = useMemo(() => {
    const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();
    const days = [];

    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<div key={`empty-${i}`} className="datepicker-day-empty" />);
    }

    for (let i = 1; i <= daysInMonth; i++) {
      const isDisabled = !isDaySelectable(i);
      days.push(
        <DatepickerDayButton
          key={i}
          day={i}
          isActive={isDayActive(i)}
          isInRange={isDayInRange(i)}
          isStart={
            i === tempStartDate?.getDate() &&
            tempStartDate?.getMonth() === currentDate.getMonth() &&
            tempStartDate?.getFullYear() === currentDate.getFullYear()
          }
          isEnd={
            i === tempEndDate?.getDate() &&
            tempEndDate?.getMonth() === currentDate.getMonth() &&
            tempEndDate?.getFullYear() === currentDate.getFullYear()
          }
          onClick={() => handleDayClick(i)}
          isDisabled={isDisabled}
        />
      );
    }

    while (days.length % 7 !== 0) {
      days.push(<div key={`extra-${days.length}`} className="datepicker-day-empty" />);
    }

    return days;
  }, [currentDate, tempStartDate, tempEndDate]);

  // Add swipe handlers
  const swipeHandlers = useSwipeable({
    onSwipedUp: handleNextMonth,
    onSwipedDown: handlePreviousMonth,
    preventScrollOnSwipe: true,
    trackMouse: true, // Allow mouse drag for desktop users
  });

  const handleDoneClick = () => onClose();

  return (
    <div className={`custom-calendar ${isOpen ? "open" : ""}`}>
      <div className="calendar-header">
        <div className="calendar-header-inner">
          <div className="month-container bottom-shadow">
            <LeftMonthButton onClick={handlePreviousMonth} />
            <MonthButton>{months[currentDate.getMonth()]}</MonthButton>
            <RightMonthButton onClick={handleNextMonth} />
          </div>
          <YearButton>{currentDate.getFullYear()}</YearButton>
        </div>
      </div>
      <div className="datepicker-day-names">
        {dayNames.map((dayName, index) => (
          <div key={index} className="datepicker-day-name">
            {dayName}
          </div>
        ))}
      </div>
      <div
        className={`calendar-days ${getNumberOfRows() === 5 ? "tight-rows" : ""}`}
        {...swipeHandlers} // Attach swipe handlers
      >
        {renderDays}
      </div>
      <div>
        <DatepickerDoneButton onClick={handleDoneClick} />
      </div>
    </div>
  );
};

export default CustomCalendar;
