import { combineReducers } from 'redux';
import offerReducer from './offerReducer';
import transferReducer from './transferReducer';
import tripOrganisationReducer from "./tripOrganisationReducer";

const rootReducer = combineReducers({
  offer: offerReducer,
  transfer: transferReducer,
  tripOrganisation: tripOrganisationReducer,
});

export default rootReducer;