export const SET_MIN_DATE = 'SET_MIN_DATE';
export const SET_START_DATE = 'SET_START_DATE';
export const RESET_START_DATE = 'RESET_START_DATE';
export const setMinimumSelectableDate = (date) => ({
    type: SET_MIN_DATE,
    payload: date
});

export const setStartDateInDatepicker = (startDate) => ({
    type: SET_START_DATE,
    payload: startDate
});

export const resetStartDate = () => ({
    type: RESET_START_DATE
});