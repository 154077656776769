import React from 'react';
import PreviousButton from "../tripOrganisation/buttons/previousButton";
import NextButton from "../tripOrganisation/buttons/nextButton";
import './styles.css';

const Navigation = ({
                        onPreviousClick,
                        onNextClick,
                        nextDisabled = false,

                    }) => {

    return (
        <div className="navigation-wrapper">
            <div className="navigation">
                {
                    <PreviousButton onClick={onPreviousClick}/>
                }
                {
                    <NextButton onClick={onNextClick} disabled={nextDisabled}/>
                }
            </div>
        </div>
    );
};

export default Navigation;
