import {formatCurrency} from "./calculationFunctions";
import Cookies from "js-cookie";

/**
 * Function to safely extract hotel details from the hotelInfo object.
 * @param {Object} hotelInfo - real data object containing hotels data.
 * @param {Object} currentOfferDetailsHotel - object where extracted data is stored
 * @param {number} optionIndex - The index of the specific hotel offer, can be 1,2 and 3
 *
 * @returns {Object|null} - The extracted hotel details or null if not found.
 */
function processAccommodationInfo(hotelInfo, optionIndex, currentOfferDetailsHotel) {
    try {
        let totalPrice = hotelInfo.dynamic_data.payment_options.payment_types[0].amount;
        let currency = hotelInfo.dynamic_data.payment_options.payment_types[0].currency_code;
        let hotelName = hotelInfo.static_data.name;
        let checkInTime = hotelInfo.static_data.check_in_time;
        let checkOutTime = hotelInfo.static_data.check_out_time;
        let starRating = hotelInfo.static_data.star_rating;
        let amenityGroups = hotelInfo.static_data.amenity_groups;
        let hotelImages = hotelInfo.static_data.images;
        let roomInfo = hotelInfo.dynamic_data.room_data_trans.main_name;

        // Ensure the structure for optionIndex exists in selection-box and details
        currentOfferDetailsHotel.info1["selection-box"][`option${optionIndex}`] = currentOfferDetailsHotel.info1["selection-box"][`option${optionIndex}`] || {};
        currentOfferDetailsHotel.info1.detailsGroup1[optionIndex - 1] = currentOfferDetailsHotel.info1.detailsGroup1[optionIndex - 1] || {segments: [{}]};

        currentOfferDetailsHotel.info1["selection-box"][`option${optionIndex}`].price = `${totalPrice}${formatCurrency(currency)}`;
        currentOfferDetailsHotel.info1["selection-box"][`option${optionIndex}`].name = hotelName;
        currentOfferDetailsHotel.info1.detailsGroup1[optionIndex - 1].segments[0].name = hotelName;
        currentOfferDetailsHotel.info1.detailsGroup1[optionIndex - 1].segments[0].checkInTime = checkInTime;
        currentOfferDetailsHotel.info1.detailsGroup1[optionIndex - 1].segments[0].checkOutTime = checkOutTime;
        currentOfferDetailsHotel.info1.detailsGroup1[optionIndex - 1].segments[0].stars = starRating;
        currentOfferDetailsHotel.info1.detailsGroup1[optionIndex - 1].segments[0].amenity_groups = amenityGroups;
        currentOfferDetailsHotel.info1.detailsGroup1[optionIndex - 1].images = hotelImages;
        currentOfferDetailsHotel.info1.detailsGroup1[optionIndex - 1].segments[0].roomInfo = roomInfo;

    } catch (error) {
        console.log(`No hotel ${optionIndex} info`);
        console.error(error);
    }
    return currentOfferDetailsHotel;
}

// Function to process flight to destination data
const processFlightToDestination = (offerData, selectionBox, detailsGroup) => {
    if (!offerData || !Array.isArray(offerData)) {
        console.log("No real offer details available for departure");
        return;
    }

    updateSelectionBox(selectionBox, offerData);
    updateDetailsGroup(detailsGroup, offerData);
};


// Function to process return flight details
const processFlightFromDestination = (offerData, selectionBox, detailsGroup) => {
    if (!offerData || !Array.isArray(offerData)) {
        console.log("No real offer details available for return");
        return;
    }

    offerData.forEach((offer, index) => {
        const option = `option${index + 1}`;
        if (offer && offer[0][0]["oneWay"]) {
            const oneWayOffer = offer[0][1];
            selectionBox[option] = {
                price: `${oneWayOffer["price"]["grandTotal"]}${formatCurrency(oneWayOffer["price"]["currency"])}`,
                time: oneWayOffer.itineraries[0].segments[0].departure.at,
            };
            detailsGroup[index] = {
                totalDuration: oneWayOffer.itineraries[0].duration,
                segments: oneWayOffer.itineraries[0].segments,
                travelerInfo: {
                    price: oneWayOffer.price,
                    pricingOptions: oneWayOffer.pricingOptions,
                    travelerPricings: oneWayOffer.travelerPricings,
                }
            };
        } else if (offer) {
            const roundTripOffer = offer[0][0];
            selectionBox[option] = {
                price: `0${formatCurrency(roundTripOffer["price"]["currency"])}`,
                time: roundTripOffer.itineraries[1].segments[0].departure.at,
            };
            detailsGroup[index] = {
                totalDuration: roundTripOffer.itineraries[1].duration,
                segments: roundTripOffer.itineraries[1].segments,
                travelerInfo: {
                    price: roundTripOffer.price,
                    pricingOptions: roundTripOffer.pricingOptions,
                    travelerPricings: roundTripOffer.travelerPricings,
                }
            };
        }
    });
};


const processTransfer = (transferData, transferDetails, index, transferTypeKey, optionKey) => {
    if (!transferData || !transferData.message[index] || transferData.message === "No transfers found") {
        transferDetails.info1["selection-box"][optionKey] = {price: 0, time: 0};
        transferDetails.info1.detailsGroup1[0] = {
            segments: [{
                start: {dateTime: 0, locationCode: 0},
                end: {dateTime: 0},
                transferType: 0
            }]
        };
    } else {
        const transfer = transferData.message[index][transferTypeKey];
        transferDetails.info1["selection-box"][optionKey] = {
            price: `${transfer.converted.monetaryAmount}${formatCurrency(transfer.converted.currencyCode)}`,
            time: transfer.start.dateTime
        };
        transferDetails.info1.detailsGroup1[0] = {
            segments: [{
                start: {
                    dateTime: transfer.start.dateTime,
                    locationCode: transfer.start.locationCode
                },
                end: {dateTime: transfer.end.dateTime},
                transferType: transfer.transferType
            }]
        };
    }
};

// Usage for both accommodations
const processTransferToAccommodation = (transferData, transferDetails, index) => {
    processTransfer(transferData, transferDetails, index, 'transfer_to_hotel', 'option1');
};

const processTransferFromAccommodation = (transferData, transferDetails, index) => {
    processTransfer(transferData, transferDetails, index, 'transfer_to_origin', 'option1');
};


/**
 * Function to get cookies set after first step in order to use data on offers page
 * **/
const getRequestParamsFromCookies = () => {
    // Retrieve the cookie by its name, which stores JSON string
    const params = Cookies.get('request_params');
    // Check if the params exist and are not undefined
    if (params) {
        try {
            // Parse the JSON string back into an object
            const paramsObj = JSON.parse(params);
            // Return the departureDate or any other required value
            return paramsObj
        } catch (error) {
            console.error("Error parsing JSON from cookies", error);
            return null;  // Return null or default value in case of error
        }
    }
    return null; // Return null if the cookie doesn't exist
};


function countFirstLevelChildren(json) {
    if (typeof json === 'object' && json !== null) {
        return Object.keys(json).length; // Returns the number of direct children (first level)
    }
    return 0; // Return 0 if it's not a valid object
}

const isOfferValid = (currentRealOfferDetails) => {
    if (!currentRealOfferDetails) {
        console.log("offer is null or missing", currentRealOfferDetails);
        return false;
    }
    let offerData;
    try {
        offerData = currentRealOfferDetails.offers.data;

        // Check if all offers have length === 0
        const allOffersEmpty = offerData.every(offer => offer[1].length === 0);

        if (allOffersEmpty) {
            return false;
        }
    } catch (error) {
        console.log("error while checking data validity ", error);
    }
    // Additional check for "no hotels found on the location"
    if (offerData === "no hotels found on the location") {
        return false;
    }
    return true;
};

export const createOffer = (id, selectionBoxOptions = {}, selectionBoxOptions2 = {}, detailsGroup1 = [], detailsGroup2 = []) => ({
    id,
    info1: {
        "selection-box": {...selectionBoxOptions},
        "selection-box-2": {...selectionBoxOptions2},
        detailsGroup1,
        detailsGroup2,
    }
});

const generateOffer = () => {
    // Shared selection box options for the 1st and 5th items
    const sharedSelectionBoxOptions = {
        option1: {price: '', time: ''},
        option2: {price: '', time: ''},
        option3: {price: '', time: ''},
    };

    // Details for the first and fifth items, split into two groups
    const detailsGroup1 = [
        {segments: [], totalDuration: ''},
        {segments: [], totalDuration: ''},
        {segments: [], totalDuration: ''},
    ];

    const detailsGroup2 = [
        {segments: [], totalDuration: ''},
        {segments: [], totalDuration: ''},
        {segments: [], totalDuration: ''},
    ];

    return {
        destination: `Sample Destination`,
        offers: [
            // 1st item with details split into two groups
            createOffer(1, {...sharedSelectionBoxOptions}, {...sharedSelectionBoxOptions}, detailsGroup1, detailsGroup2),

            // 2nd item
            createOffer(2, {
                option1: {price: '', time: ''}
            }, {}, [
                {
                    segments: [{
                        start: {dateTime: "", locationCode: ""},
                        end: {dateTime: ""},
                        transferType: "",
                        vehicle: {
                            code: "",
                            category: "",
                            duration: "",
                            seats: [{count: ""}],
                            baggages: [{count: "", size: ""}],
                            imageURL: ""
                        }
                    }]
                }
            ]),

            // 3rd item
            createOffer(3),

            // 4th item
            createOffer(4, {
                option1: {price: '', time: ''}
            }, {}, [
                {
                    segments: [{
                        start: {dateTime: "", locationCode: ""},
                        end: {dateTime: ""},
                        vehicle: {
                            code: "",
                            category: "",
                            duration: "",
                            seats: [{count: ""}],
                            baggages: [{count: "", size: ""}],
                            imageURL: ""
                        }
                    }]
                }
            ]),
        ]
    };
};

const updateSelectionBox = (selectionBox, offerData) => {
    offerData.forEach((offer, index) => {
        const realOfferFlightInfo = offer[0][0];
        const option = `option${index + 1}`;
        selectionBox[option] = {
            price: `${realOfferFlightInfo["price"]["grandTotal"]}${formatCurrency(realOfferFlightInfo["price"]["currency"])}`,
            time: realOfferFlightInfo.itineraries[0].segments[0].departure.at,
        };
    });
};

const updateDetailsGroup = (detailsGroup, offerData) => {
    offerData.forEach((offer, index) => {
        const realOfferFlightInfo = offer[0][0];
        detailsGroup[index] = {
            totalDuration: realOfferFlightInfo.itineraries[0].duration,
            segments: realOfferFlightInfo.itineraries[0].segments,
            travelerInfo: {
                price: realOfferFlightInfo.price,
                pricingOptions: realOfferFlightInfo.pricingOptions,
                travelerPricings: realOfferFlightInfo.travelerPricings,
            }
        };
    });
};

const getDestinationAirport = (offerData) => {
    const segments = offerData.offers[0].info1.detailsGroup1[0].segments;

    // Ensure segments array exists and has at least one element
    if (segments && segments.length > 0) {
        const lastSegment = segments[segments.length - 1]; // Get the last segment
        const iataCode = lastSegment.arrival.iataCode; // Access the IATA code
        return iataCode;
    } else {
        console.error("No segments found");
        return null; // Or handle the absence of segments as needed
    }

}

const generateMockedOffer = () => {
    return {
        "destination": "Paris",
        "offers": [
            {
                "id": 1,
                "info1": {
                    "selection-box": {
                        "option1": {
                            "price": "657$",
                            "time": "11:15:00"
                        },
                        "option2": {
                            "price": "722$",
                            "time": "06:35:00"
                        },
                        "option3": {
                            "price": "866$",
                            "time": "04:22:00"
                        }
                    },
                    "details": [
                        {
                            "segments": [
                                {
                                    "departure": {
                                        "terminal": "1",
                                        "iataCode": "BEG",
                                        "at": "2024-06-29T15:20:00"
                                    },
                                    "arrival": {
                                        "iataCode": "LHR",
                                        "at": "2024-06-29T19:45:00"
                                    },
                                    "carrier": "Air Montenegro",
                                    "baggage": "no baggage",
                                    "numberOfStops": "0",
                                    "flightNumber": "230",
                                    "duration": "PT1H45M",
                                    "fareDetailsBySegment": {
                                        "travelerId": "1",
                                        "fareOption": "STANDARD",
                                        "travelerType": "CHILD",
                                        "amenities": [
                                            {
                                                "description": "PRE RESERVED SEAT ASSIGNMENT",
                                                "isChargeable": true,
                                                "amenityType": "PRE_RESERVED_SEAT",
                                                "amenityProvider": {
                                                    "name": "BrandedFare"
                                                }
                                            },
                                            {
                                                "description": "MEAL",
                                                "isChargeable": true,
                                                "amenityType": "MEAL",
                                                "amenityProvider": {
                                                    "name": "BrandedFare"
                                                }
                                            }
                                        ]
                                    }
                                }
                            ],
                            "totalDuration": "PT5H15M"
                        },
                        {
                            "segments": [
                                {
                                    "departure": {
                                        "terminal": "2",
                                        "iataCode": "BEG",
                                        "at": "2024-06-29T07:35:00"
                                    },
                                    "arrival": {
                                        "iataCode": "LHR",
                                        "at": "2024-06-29T09:55:00"
                                    },
                                    "carrier": "Fly Emirates",
                                    "baggage": "24kg",
                                    "numberOfStops": "0",
                                    "flightNumber": "892",
                                    "duration": "PT2H00M",
                                    "fareDetailsBySegment": {
                                        "travelerId": "1",
                                        "fareOption": "STANDARD",
                                        "travelerType": "ADULT",
                                        "amenities": [
                                            {
                                                "description": "PRE RESERVED SEAT ASSIGNMENT",
                                                "isChargeable": true,
                                                "amenityType": "PRE_RESERVED_SEAT",
                                                "amenityProvider": {
                                                    "name": "BrandedFare"
                                                }
                                            },
                                            {
                                                "description": "MEAL",
                                                "isChargeable": true,
                                                "amenityType": "MEAL",
                                                "amenityProvider": {
                                                    "name": "BrandedFare"
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    "departure": {
                                        "terminal": "2",
                                        "iataCode": "LHR",
                                        "at": "2024-06-30T09:44:00"
                                    },
                                    "arrival": {
                                        "iataCode": "LAX",
                                        "at": "2024-06-30T16:47:00"
                                    },
                                    "carrier": "KLM",
                                    "baggage": "24kg",
                                    "numberOfStops": "0",
                                    "flightNumber": "1922",
                                    "duration": "PT8H30M",
                                    "fareDetailsBySegment": {
                                        "travelerId": "1",
                                        "fareOption": "STANDARD",
                                        "travelerType": "ADULT",
                                        "amenities": [
                                            {
                                                "description": "PRE RESERVED SEAT ASSIGNMENT",
                                                "isChargeable": true,
                                                "amenityType": "PRE_RESERVED_SEAT",
                                                "amenityProvider": {
                                                    "name": "BrandedFare"
                                                }
                                            },
                                            {
                                                "description": "MEAL",
                                                "isChargeable": true,
                                                "amenityType": "MEAL",
                                                "amenityProvider": {
                                                    "name": "BrandedFare"
                                                }
                                            }
                                        ]
                                    }
                                }
                            ],
                            "totalDuration": "PT20H55M"
                        },
                        {
                            "segments": [
                                {
                                    "departure": {
                                        "terminal": "1",
                                        "iataCode": "BEG",
                                        "at": "2024-06-29T01:20:00"
                                    },
                                    "arrival": {
                                        "iataCode": "LHR",
                                        "at": "2024-06-29T03:20:00"
                                    },
                                    "carrier": "Air Serbia",
                                    "baggage": "24kg",
                                    "numberOfStops": "1",
                                    "flightNumber": "400",
                                    "duration": "PT0H55M",
                                    "fareDetailsBySegment": {
                                        "travelerId": "1",
                                        "fareOption": "STANDARD",
                                        "travelerType": "ADULT",
                                        "amenities": [
                                            {
                                                "description": "PRE RESERVED SEAT ASSIGNMENT",
                                                "isChargeable": true,
                                                "amenityType": "PRE_RESERVED_SEAT",
                                                "amenityProvider": {
                                                    "name": "BrandedFare"
                                                }
                                            },
                                            {
                                                "description": "MEAL",
                                                "isChargeable": true,
                                                "amenityType": "MEAL",
                                                "amenityProvider": {
                                                    "name": "BrandedFare"
                                                }
                                            }
                                        ]
                                    }
                                }
                            ],
                            "totalDuration": "PT2H12M"
                        }
                    ]
                }
            },
            {
                "id": 2,
                "title": "Airport -> Hotel",
                "info1": {
                    "selection-box": {
                        "option1": {
                            "price": "35$",
                            "time": "15:15"
                        },
                        "option2": {
                            "price": "23$",
                            "time": "07:35"
                        },
                        "option3": {
                            "price": "41$",
                            "time": "01:20"
                        }
                    },
                    "details": [
                        {
                            "segments": [
                                {
                                    "start": {
                                        "dateTime": "2019-11-10T09:55:00",
                                        "locationCode": "CDG"
                                    },
                                    "vehicle": {
                                        "code": "VAN",
                                        "category": "BU",
                                        "duration": "23 min",
                                        "seats": [
                                            {
                                                "count": 3
                                            }
                                        ],
                                        "baggages": [
                                            {
                                                "count": 3,
                                                "size": "M"
                                            }
                                        ],
                                        "imageURL": "https://provider.com/images/BU_VAN.png"
                                    }
                                }
                            ]
                        }
                    ]
                }
            },
            {
                "id": 3,
                "title": "Accommodation",
                "info1": {
                    "selection-box": {
                        "option1": {
                            "price": "1770$",
                            "name": "Catalonia Barcelona 505"
                        },
                        "option2": {
                            "price": "2390$",
                            "name": "Catalonia Park Putset"
                        },
                        "option3": {
                            "price": "1880$",
                            "name": "Acevi Villaroel"
                        }
                    },
                    "details": [
                        {
                            "segments": [
                                {
                                    "name": "Catalonia Barcelona 505",
                                    "stars": "3",
                                    "checkInDate": "2024-07-21",
                                    "checkOutDate": "2024-07-28",
                                    "checkInTime": "17:00",
                                    "checkOutTime": "11.30",
                                    "room": [
                                        {
                                            "beds": 1,
                                            "bedType": "DOUBLE"
                                        }
                                    ],
                                    "amenity_groups": [
                                        {
                                            "amenities": [
                                                "ATM",
                                                "Shopping on site",
                                                "Computer",
                                                "Air conditioning",
                                                "24-hour reception",
                                                "Elevator/lift",
                                                "Currency exchange",
                                                "Smoke-free property",
                                                "Heating",
                                                "Newspapers",
                                                "Ticket assistance",
                                                "Gift shop",
                                                "Express check-in/check-out",
                                                "Garden",
                                                "Television in lobby",
                                                "Terrace",
                                                "All Spaces Non-Smoking (public and private)",
                                                "Electric car charging"
                                            ],
                                            "group_name": "General"
                                        },
                                        {
                                            "amenities": [
                                                "Bridal suite",
                                                "Non-smoking rooms",
                                                "Soundproof rooms",
                                                "Room service",
                                                "Family room",
                                                "TV",
                                                "Hairdryer",
                                                "Wardrobe/Closet"
                                            ],
                                            "group_name": "Rooms"
                                        },
                                        {
                                            "amenities": [
                                                "Accessibility features",
                                                "Wheelchair Accessible",
                                                "Wheelchair access to restaurant"
                                            ],
                                            "group_name": "Accessibility"
                                        },
                                        {
                                            "amenities": [
                                                "Ironing",
                                                "Luggage storage",
                                                "Laundry",
                                                "Safe-deposit box",
                                                "Concierge services",
                                                "Dry-cleaning",
                                                "Shoe shine",
                                                "Luggage storage",
                                                "Telephone"
                                            ],
                                            "group_name": "Services and amenities"
                                        },
                                        {
                                            "amenities": [
                                                "Bar",
                                                "Breakfast",
                                                "Breakfast in the room",
                                                "Cafe",
                                                "Restaurant",
                                                "Breakfast/lunch to go",
                                                "Packed Lunches"
                                            ],
                                            "group_name": "Meals"
                                        },
                                        {
                                            "amenities": [
                                                "Free Wi-Fi"
                                            ],
                                            "group_name": "Internet"
                                        },
                                        {
                                            "amenities": [
                                                "Car rental",
                                                "Airport transportation"
                                            ],
                                            "group_name": "Transfer"
                                        },
                                        {
                                            "amenities": [
                                                "Spanish",
                                                "German",
                                                "English",
                                                "Multi-language staff"
                                            ],
                                            "group_name": "Languages Spoken"
                                        },
                                        {
                                            "amenities": [
                                                "Tour assistance"
                                            ],
                                            "group_name": "Tourist services"
                                        },
                                        {
                                            "amenities": [
                                                "Hiking"
                                            ],
                                            "group_name": "Recreation"
                                        },
                                        {
                                            "amenities": [
                                                "Parking",
                                                "Parking nearby",
                                                "Garage"
                                            ],
                                            "group_name": "Parking"
                                        },
                                        {
                                            "amenities": [
                                                "Business center",
                                                "Event facilities",
                                                "Fax and copy machine",
                                                "Meeting and presentation facilities",
                                                "Conference Hall"
                                            ],
                                            "group_name": "Business"
                                        },
                                        {
                                            "amenities": [
                                                "Fitness facilities",
                                                "Golf \u0441ourse (within 3 km)"
                                            ],
                                            "group_name": "Sports"
                                        },
                                        {
                                            "amenities": [
                                                "Massage",
                                                "Steam room",
                                                "Sauna",
                                                "Spa",
                                                "First Aid Kit"
                                            ],
                                            "group_name": "Beauty and wellness"
                                        },
                                        {
                                            "amenities": [
                                                "Babysitting and childcare"
                                            ],
                                            "group_name": "Kids"
                                        },
                                        {
                                            "amenities": [
                                                "Pets allowed"
                                            ],
                                            "group_name": "Pets"
                                        },
                                        {
                                            "amenities": [
                                                "Personal protection equipment for staff",
                                                "Extra decontamination measures",
                                                "Personal protection equipment for guests",
                                                "Individually packaged food",
                                                "Additional measures against COVID-19"
                                            ],
                                            "group_name": "Health and Safety Measures"
                                        }
                                    ],
                                    "freeCancellationUntil": "2024-06-11"
                                }
                            ],
                            "images": [
                                "https://cdn.worldota.net/t/240x240/content/ed/b0/edb0bfa4f7b9b94980a0876155f236ae0e91edbe.jpeg",
                                "https://cdn.worldota.net/t/x500/content/a3/37/a3377e709e575d353ce9f62446dc3b4e75244220.jpeg",
                                "https://cdn.worldota.net/t/1024x768/content/27/1a/271ad44de6f05e1f01f464f6618ab61f99a1f924.jpeg"
                            ]
                        },
                        {
                            "segments": [
                                {
                                    "name": "Catalonia Park Putset",
                                    "stars": "5",
                                    "checkInDate": "2024-06-17",
                                    "checkOutDate": "2024-06-22",
                                    "checkInTime": "16.00",
                                    "checkOutTime": "10.00",
                                    "room": [
                                        {
                                            "beds": 1,
                                            "bedType": "DOUBLE"
                                        },
                                        {
                                            "beds": 1,
                                            "bedType": "SINGLE"
                                        }
                                    ],
                                    "amenity_groups": [
                                        {
                                            "amenities": [
                                                "Shopping on site",
                                                "Computer",
                                                "Air conditioning",
                                                "24-hour reception",
                                                "Elevator/lift",
                                                "Currency exchange",
                                                "Smoke-free property",
                                                "Heating",
                                                "Security guard",
                                                "Newspapers",
                                                "Ticket assistance",
                                                "Gift shop",
                                                "Express check-in/check-out",
                                                "Terrace"
                                            ],
                                            "group_name": "General"
                                        },
                                        {
                                            "amenities": [
                                                "Non-smoking rooms",
                                                "Room service",
                                                "Family room",
                                                "Cable TV",
                                                "Minibar",
                                                "Hairdryer",
                                                "Flat-screen TV",
                                                "Shower/Bathtub",
                                                "Shower"
                                            ],
                                            "group_name": "Rooms"
                                        },
                                        {
                                            "amenities": [
                                                "Accessibility features"
                                            ],
                                            "group_name": "Accessibility"
                                        },
                                        {
                                            "amenities": [
                                                "Luggage storage",
                                                "Laundry",
                                                "Safe-deposit box",
                                                "Concierge services",
                                                "Dry-cleaning",
                                                "Luggage storage",
                                                "Wake-up service",
                                                "Telephone"
                                            ],
                                            "group_name": "Services and amenities"
                                        },
                                        {
                                            "amenities": [
                                                "Bar",
                                                "Diet menu (on request)",
                                                "Breakfast",
                                                "Buffet breakfast",
                                                "Breakfast in the room",
                                                "Restaurant",
                                                "Snack bar",
                                                "Packed Lunches"
                                            ],
                                            "group_name": "Meals"
                                        },
                                        {
                                            "amenities": [
                                                "Free Wi-Fi",
                                                "In-room internet"
                                            ],
                                            "group_name": "Internet"
                                        },
                                        {
                                            "amenities": [
                                                "Airport transportation",
                                                "Transfer services"
                                            ],
                                            "group_name": "Transfer"
                                        },
                                        {
                                            "amenities": [
                                                "Spanish",
                                                "German",
                                                "English",
                                                "Italian",
                                                "French",
                                                "Multi-language staff"
                                            ],
                                            "group_name": "Languages Spoken"
                                        },
                                        {
                                            "amenities": [
                                                "Tour assistance"
                                            ],
                                            "group_name": "Tourist services"
                                        },
                                        {
                                            "amenities": [
                                                "Bike rental",
                                                "Billiards"
                                            ],
                                            "group_name": "Recreation"
                                        },
                                        {
                                            "amenities": [
                                                "Parking",
                                                "Free parking nearby"
                                            ],
                                            "group_name": "Parking"
                                        },
                                        {
                                            "amenities": [
                                                "Business center",
                                                "Fax machine",
                                                "Meeting and presentation facilities",
                                                "Conference Hall"
                                            ],
                                            "group_name": "Business"
                                        },
                                        {
                                            "amenities": [
                                                "Fitness facilities"
                                            ],
                                            "group_name": "Sports"
                                        },
                                        {
                                            "amenities": [
                                                "Doctor",
                                                "Steam room",
                                                "Sauna",
                                                "First Aid Kit"
                                            ],
                                            "group_name": "Beauty and wellness"
                                        },
                                        {
                                            "amenities": [
                                                "Pets allowed"
                                            ],
                                            "group_name": "Pets"
                                        },
                                        {
                                            "amenities": [
                                                "Temperature control for staff",
                                                "Personal protection equipment for staff",
                                                "Extra decontamination measures",
                                                "Temperature control for guests",
                                                "Personal protection equipment for guests",
                                                "Contactless check-in and/or check-out",
                                                "Additional measures against COVID-19"
                                            ],
                                            "group_name": "Health and Safety Measures"
                                        }
                                    ],
                                    "freeCancellationUntil": "2024-06-02"
                                }
                            ],
                            "images": [
                                "https://cdn.worldota.net/t/200x200/content/47/d2/47d23c30ee3a87714401247c7a749697691db196.jpeg",
                                "https://cdn.worldota.net/t/x500/content/a3/37/a3377e709e575d353ce9f62446dc3b4e75244220.jpeg",
                                "https://cdn.worldota.net/t/1024x768/content/ff/c8/ffc848eb1f24f599ecd55fa9bb1986fbcc17af34.jpeg"
                            ]
                        },
                        {
                            "segments": [
                                {
                                    "name": "Acevi Villaroel",
                                    "stars": "4",
                                    "checkInDate": "2024-04-28",
                                    "checkOutDate": "2024-05-03",
                                    "checkInTime": "15.00",
                                    "checkOutTime": "11.00",
                                    "room": [
                                        {
                                            "beds": 2,
                                            "bedType": "SINGLE"
                                        }
                                    ],
                                    "amenity_groups": [
                                        {
                                            "amenities": [
                                                "ATM",
                                                "Shopping on site",
                                                "Computer",
                                                "Air conditioning",
                                                "24-hour reception",
                                                "Elevator/lift",
                                                "Currency exchange",
                                                "Smoke-free property",
                                                "Heating",
                                                "Newspapers",
                                                "Ticket assistance",
                                                "Gift shop",
                                                "Express check-in/check-out",
                                                "Garden",
                                                "Television in lobby",
                                                "Terrace",
                                                "All Spaces Non-Smoking (public and private)",
                                                "Electric car charging"
                                            ],
                                            "group_name": "General"
                                        },
                                        {
                                            "amenities": [
                                                "Bridal suite",
                                                "Non-smoking rooms",
                                                "Soundproof rooms",
                                                "Room service",
                                                "Family room",
                                                "TV",
                                                "Hairdryer",
                                                "Wardrobe/Closet"
                                            ],
                                            "group_name": "Rooms"
                                        },
                                        {
                                            "amenities": [
                                                "Accessibility features",
                                                "Wheelchair Accessible",
                                                "Wheelchair access to restaurant"
                                            ],
                                            "group_name": "Accessibility"
                                        },
                                        {
                                            "amenities": [
                                                "Ironing",
                                                "Luggage storage",
                                                "Laundry",
                                                "Safe-deposit box",
                                                "Concierge services",
                                                "Dry-cleaning",
                                                "Shoe shine",
                                                "Luggage storage",
                                                "Telephone"
                                            ],
                                            "group_name": "Services and amenities"
                                        },
                                        {
                                            "amenities": [
                                                "Bar",
                                                "Breakfast",
                                                "Breakfast in the room",
                                                "Cafe",
                                                "Restaurant",
                                                "Breakfast/lunch to go",
                                                "Packed Lunches"
                                            ],
                                            "group_name": "Meals"
                                        },
                                        {
                                            "amenities": [
                                                "Free Wi-Fi"
                                            ],
                                            "group_name": "Internet"
                                        },
                                        {
                                            "amenities": [
                                                "Car rental",
                                                "Airport transportation"
                                            ],
                                            "group_name": "Transfer"
                                        },
                                        {
                                            "amenities": [
                                                "Spanish",
                                                "German",
                                                "English",
                                                "Multi-language staff"
                                            ],
                                            "group_name": "Languages Spoken"
                                        },
                                        {
                                            "amenities": [
                                                "Tour assistance"
                                            ],
                                            "group_name": "Tourist services"
                                        },
                                        {
                                            "amenities": [
                                                "Hiking"
                                            ],
                                            "group_name": "Recreation"
                                        },
                                        {
                                            "amenities": [
                                                "Parking",
                                                "Parking nearby",
                                                "Garage"
                                            ],
                                            "group_name": "Parking"
                                        },
                                        {
                                            "amenities": [
                                                "Business center",
                                                "Event facilities",
                                                "Fax and copy machine",
                                                "Meeting and presentation facilities",
                                                "Conference Hall"
                                            ],
                                            "group_name": "Business"
                                        },
                                        {
                                            "amenities": [
                                                "Fitness facilities",
                                                "Golf \u0441ourse (within 3 km)"
                                            ],
                                            "group_name": "Sports"
                                        },
                                        {
                                            "amenities": [
                                                "Massage",
                                                "Steam room",
                                                "Sauna",
                                                "Spa",
                                                "First Aid Kit"
                                            ],
                                            "group_name": "Beauty and wellness"
                                        },
                                        {
                                            "amenities": [
                                                "Babysitting and childcare"
                                            ],
                                            "group_name": "Kids"
                                        },
                                        {
                                            "amenities": [
                                                "Pets allowed"
                                            ],
                                            "group_name": "Pets"
                                        },
                                        {
                                            "amenities": [
                                                "Personal protection equipment for staff",
                                                "Extra decontamination measures",
                                                "Personal protection equipment for guests",
                                                "Individually packaged food",
                                                "Additional measures against COVID-19"
                                            ],
                                            "group_name": "Health and Safety Measures"
                                        }
                                    ],
                                    "freeCancellationUntil": "2024-03-25"

                                }
                            ],
                            "images": [
                                "https://cdn.worldota.net/t/200x200/content/47/d2/47d23c30ee3a87714401247c7a749697691db196.jpeg",
                                "https://cdn.worldota.net/t/x500/content/a3/37/a3377e709e575d353ce9f62446dc3b4e75244220.jpeg",
                                "https://cdn.worldota.net/t/1024x768/content/ff/c8/ffc848eb1f24f599ecd55fa9bb1986fbcc17af34.jpeg"
                            ]
                        }
                    ]
                }
            },
            {
                "id": 4,
                "title": "Hotel -> Airport",
                "info1": {
                    "selection-box": {
                        "option1": {
                            "price": "19$",
                            "time": "21:10"
                        },
                        "option2": {
                            "price": "27.89$",
                            "time": "21:35"
                        },
                        "option3": {
                            "price": "22$",
                            "time": "21:20"
                        }
                    },
                    "details": [
                        {
                            "segments": [
                                {
                                    "start": {
                                        "dateTime": "2019-11-10T10:45:00",
                                        "locationCode": "CDG"
                                    },
                                    "vehicle": {
                                        "code": "Taxi",
                                        "category": "BU",
                                        "duration": "31 min",
                                        "seats": [
                                            {
                                                "count": 3
                                            }
                                        ],
                                        "baggages": [
                                            {
                                                "count": 3,
                                                "size": "M"
                                            }
                                        ],
                                        "imageURL": "https://provider.com/images/BU_VAN.png"
                                    }
                                }
                            ]
                        }
                    ]
                }
            },
            {
                "id": 5,
                "title": "Return",
                "info1": {
                    "selection-box": {
                        "option1": {
                            "price": "657$",
                            "time": "15:15"
                        },
                        "option2": {
                            "price": "722$",
                            "time": "07:35"
                        },
                        "option3": {
                            "price": "866$",
                            "time": "01:20"
                        }
                    },
                    "details": [
                        {
                            "segments": [
                                {
                                    "departure": {
                                        "terminal": "2",
                                        "iataCode": "LHR",
                                        "at": "2024-06-29T00:15:00"
                                    },
                                    "arrival": {
                                        "iataCode": "BEG",
                                        "at": "2024-06-29T03:35:00"
                                    },
                                    "carrier": "British Airways",
                                    "baggage": "no baggage",
                                    "numberOfStops": "1",
                                    "flightNumber": "412",
                                    "duration": "PT5H25M",
                                    "fareDetailsBySegment": {
                                        "travelerId": "1",
                                        "fareOption": "STANDARD",
                                        "travelerType": "ADULT",
                                        "amenities": [
                                            {
                                                "description": "PRE RESERVED SEAT ASSIGNMENT",
                                                "isChargeable": true,
                                                "amenityType": "PRE_RESERVED_SEAT",
                                                "amenityProvider": {
                                                    "name": "BrandedFare"
                                                }
                                            },
                                            {
                                                "description": "MEAL",
                                                "isChargeable": true,
                                                "amenityType": "MEAL",
                                                "amenityProvider": {
                                                    "name": "BrandedFare"
                                                }
                                            }
                                        ]
                                    }
                                }
                            ],
                            "totalDuration": "PT41H39M"
                        },
                        {
                            "segments": [
                                {
                                    "departure": {
                                        "terminal": "1",
                                        "iataCode": "LHR",
                                        "at": "2024-06-29T08:45"
                                    },
                                    "arrival": {
                                        "iataCode": "BEG",
                                        "at": "2024-06-29T11:55"
                                    },
                                    "carrier": "Fly Dubai",
                                    "baggage": "24kg",
                                    "numberOfStops": "0",
                                    "flightNumber": "573",
                                    "duration": "PT8H30M",
                                    "fareDetailsBySegment": {
                                        "travelerId": "1",
                                        "fareOption": "STANDARD",
                                        "travelerType": "ADULT",
                                        "amenities": [
                                            {
                                                "description": "PRE RESERVED SEAT ASSIGNMENT",
                                                "isChargeable": true,
                                                "amenityType": "PRE_RESERVED_SEAT",
                                                "amenityProvider": {
                                                    "name": "BrandedFare"
                                                }
                                            },
                                            {
                                                "description": "MEAL",
                                                "isChargeable": true,
                                                "amenityType": "MEAL",
                                                "amenityProvider": {
                                                    "name": "BrandedFare"
                                                }
                                            }
                                        ]
                                    }
                                }
                            ],
                            "totalDuration": "PT13H32M"
                        },
                        {
                            "segments": [
                                {
                                    "departure": {
                                        "terminal": "1",
                                        "iataCode": "LHR",
                                        "at": "2024-06-29T13:10"
                                    },
                                    "arrival": {
                                        "iataCode": "BEG",
                                        "at": "2024-06-29T16:20"
                                    },
                                    "carrier": "Air Serbia",
                                    "baggage": "24kg",
                                    "numberOfStops": "2",
                                    "flightNumber": "810",
                                    "duration": "PT45H45M",
                                    "fareDetailsBySegment": {
                                        "travelerId": "1",
                                        "fareOption": "STANDARD",
                                        "travelerType": "ADULT",
                                        "amenities": [
                                            {
                                                "description": "PRE RESERVED SEAT ASSIGNMENT",
                                                "isChargeable": true,
                                                "amenityType": "PRE_RESERVED_SEAT",
                                                "amenityProvider": {
                                                    "name": "BrandedFare"
                                                }
                                            },
                                            {
                                                "description": "MEAL",
                                                "isChargeable": true,
                                                "amenityType": "MEAL",
                                                "amenityProvider": {
                                                    "name": "BrandedFare"
                                                }
                                            }
                                        ]
                                    }
                                }
                            ],
                            "totalDuration": "PT22H32M"
                        }
                    ]
                }
            }
        ]
    };
};

export {
    processAccommodationInfo,
    getRequestParamsFromCookies,
    processFlightFromDestination,
    processFlightToDestination,
    countFirstLevelChildren,
    processTransferToAccommodation,
    processTransferFromAccommodation,
    isOfferValid,
    generateOffer,
    generateMockedOffer,
    getDestinationAirport
};