import React, {useState, useEffect, useRef} from 'react';
import './styles.css';

const Autocomplete = ({handleInputChange, values, setIsValidSelection, setDepartureLocationError}) => {
    const initialInputValue = values.departureLocation ? values.departureLocation.split(', ').pop() : '';
    const [inputValue, setInputValue] = useState(initialInputValue);
    const [results, setResults] = useState([]);
    const [dropdownVisible, setDropdownVisible] = useState(false); // Manage dropdown visibility
    const [userHasInteracted, setUserHasInteracted] = useState(false);
    const [isFocused, setIsFocused] = useState(false); // Track whether the input is focused
    const debounceTimeout = useRef(null);
    const autocompleteRef = useRef(null);

    const preFilledPlaceholder = "Enter departure airport"; // The placeholder text to be shown initially

    const MAX_NUMBER_OF_RESULTS = 6;
    const RESULT_ITEM_HEIGHT = 41; // Set a constant for the height of each result item
    const BASE_HEIGHT = 70; // The base height of the edge-block

    useEffect(() => {

        // Regex to match the format "City (CODE)"
        const formatRegex = /^(.*) \((\w{3})\)$/;

        // Check if the input matches the format "City (CODE)"
        const match = inputValue.match(formatRegex);

        setIsValidSelection(match);

        if (!match && userHasInteracted) {
            setDepartureLocationError('Please select a valid starting point from the list.');
        } else {
            setDepartureLocationError('');
        }
    }, [inputValue, setIsValidSelection, setDepartureLocationError, userHasInteracted]);

    useEffect(() => {
        if (inputValue.length >= 2 && dropdownVisible) {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
            debounceTimeout.current = setTimeout(() => {
                let path;
                if (process.env.REACT_APP_WORKMODE === 'dev') {
                    path = `http://${process.env.REACT_APP_URL}:${process.env.REACT_APP_DJANGO_PORT}/api/autocomplete_airports/?input=${inputValue}`;
                } else {
                    path = `/api/autocomplete_airports/?input=${inputValue}`;
                }
                fetch(path)
                    .then(response => response.json())
                    .then(data => {
                        const formattedResults = data.message.map(item => ({
                            airport_code: item[0],
                            airport_name: item[1],
                            city_name: item[2],
                        }));
                        setResults(formattedResults.slice(0, MAX_NUMBER_OF_RESULTS));
                        setDropdownVisible(true); // Show the dropdown after fetching results
                    })
                    .catch(error => console.error('Error fetching data:', error));
            }, 50);
        } else {
            setResults([]);
            setDropdownVisible(false); // Hide the dropdown if input is less than 2 characters
        }
    }, [inputValue]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (autocompleteRef.current && !autocompleteRef.current.contains(event.target)) {
                setResults([]);
                setDropdownVisible(false); // Hide the dropdown when clicking outside
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const onInputChange = (e) => {
        const {value} = e.target;
        setInputValue(value);
        handleInputChange('departureLocation')({target: {value}});
        setIsValidSelection(false);
        setDropdownVisible(true); // Show the dropdown when typing
        if (!userHasInteracted) setUserHasInteracted(true);
    };

    const onItemClick = (item) => {
        // Set input value in the format: City (Code)
        setInputValue(`${item.city_name} (${item.airport_code})`);

        // Update the parent component with the selected airport code
        handleInputChange('departureLocation')({target: {value: `${item.city_name} (${item.airport_code})`}});

        // Mark the selection as valid and remove any previous error messages
        setIsValidSelection(true);
        setUserHasInteracted(true);
        setDepartureLocationError('');

        // Clear the autocomplete results and hide the dropdown
        setResults([]);
        setDropdownVisible(false);
    };

    // Calculate the dynamic height based on the number of results
    const calculatedHeight = BASE_HEIGHT + (dropdownVisible ? results.length * RESULT_ITEM_HEIGHT + 10 : 0);

    return (
        <div className="autocomplete" ref={autocompleteRef}>
            <div className="edge-block-outer" style={{height: `${calculatedHeight}px`}}>
                <div className="edge-block" style={{height: `${calculatedHeight}px`}}>
                    <div className="inner-block highlight">
                        <div className={`destination-input-field highlight`}>
                            {/* Conditionally show the placeholder text only when the input is not focused and there's no input value */}
                            {!isFocused && !inputValue &&
                                <div className="placeholder-text">{preFilledPlaceholder}</div>}
                            <input
                                type="text"
                                value={inputValue}
                                onChange={onInputChange}
                                onFocus={() => setIsFocused(true)}  // Remove the placeholder on focus
                                required
                                className={inputValue ? '' : 'placeholder-active'} // Additional class if input is empty
                            />
                        </div>
                        {dropdownVisible && results.length > 0 && (
                            <ul className="autocomplete-results">
                                {results.map((result, index) => (
                                    <li
                                        key={index}
                                        className={`autocomplete-item ${index === results.length - 1 ? 'last' : ''}`}
                                        onClick={() => onItemClick(result)}
                                    >
                                        <span className="name">{result.airport_name}, {result.city_name}</span>
                                        <span className="code">{result.airport_code}</span>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Autocomplete;
