import React, {useState, useRef, useEffect} from "react";
import Counter from "../../../counter/Counter";
import CounterChildren from "../../../counter/CounterChildren";
import Autocomplete from "../../autocomplete/autocomplete";
import "./styles.css";
import "../styles.css";
import "../../../../commonStyles.css";
import CalendarButton from "../../buttons/calendarButton";
import CustomCalendar from "../../../datepicker/datepicker";
import Navigation from "../../../footer/navigation";
import {useDispatch, useSelector} from "react-redux";
import {resetStartDate, setStartDateInDatepicker} from "../../../../store/actions/tripOrganisationActions";

function UserDataEntryStep({
                               nextStep,
                               prevStep,
                               handleInputChange,
                               handleChange,
                               values,
                           }) {
    const [isValidSelection, setIsValidSelection] = useState(false);
    const [adultsError, setAdultsError] = useState("");
    const [departureLocationError, setDepartureLocationError] = useState("");
    const [calendarOpen, setCalendarOpen] = useState(false);
    const [calendarError, setCalendarError] = useState(null);
    const [rangeNotSelectedError, setRangeNotSelectedError] = useState(false);
    const [activeButton, setActiveButton] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [dateRangeError, setDateRangeError] = useState(""); // Error message for invalid date range

    const calendarRef = useRef(null);

    const startDateFromRedux = useSelector((state) => state.tripOrganisation.startDate);

    const dispatch = useDispatch();

    useEffect(() => {
        setStartDate(startDateFromRedux);
    }, [startDateFromRedux]);

    useEffect(() => {
        dispatch(resetStartDate());
    }, []);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (calendarRef.current && !calendarRef.current.contains(event.target)) {
                setCalendarOpen(false);
                if (startDate && endDate) {
                    setRangeNotSelectedError(false);
                }
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [startDate, endDate]);

    const handleCalendarButtonClick = () => {
        setCalendarOpen(true);
    };

    const proceedToNextStep = () => {

        // Check if the number of adults is valid
        if (values.adults <= 0) {
            setAdultsError("Please add at least one adult.");
            return;
        } else {
            setAdultsError("");
        }

        // Check if a valid departure location is selected
        if (!isValidSelection) {
            setDepartureLocationError("Please select a valid departure location from the list.");
            return;
        } else {
            setDepartureLocationError("");
        }

        // Check if both dates are selected
        if (!startDate || !endDate) {
            console.log("start date", startDate);
            console.log("end date", endDate);
            setRangeNotSelectedError(true);
            setDateRangeError(""); // Clear any existing date range error
            return;
        } else {
            setRangeNotSelectedError(false);
        }

        // Check if startDate is strictly less than endDate
        if (startDate >= endDate) {
            setDateRangeError(true);
            return;
        } else {
            setDateRangeError("");
        }

        // Pass startDate and endDate to the next step
        nextStep({startDate, endDate});
    };

    const handleDateSelect = (selectedDate) => {
        if (!startDate) {
            setStartDate(selectedDate);
        }
        if (startDate && endDate) {
            setStartDate(null);
            setEndDate(null);
        }
        if (startDate && !endDate) {
            setEndDate(selectedDate);
        }

        setCalendarError(null);
    };

    return (
        <div className="form-inline-wrapper">
            {departureLocationError && (
                <div className="error-detail">{departureLocationError}</div>
            )}
            <div className="autocomplete-wrapper">
                <p className="initial-data-label">STARTING AIRPORT</p>
                <Autocomplete
                    handleInputChange={handleInputChange}
                    values={values}
                    isValidSelection={isValidSelection}
                    setIsValidSelection={setIsValidSelection}
                    setDepartureLocationError={setDepartureLocationError}
                />
            </div>

            <div className="time-range-container">
                {rangeNotSelectedError && (
                    <div className="error-detail">
                        Please select both start and end dates.
                    </div>
                )}
                {dateRangeError && (
                    <div className="error-detail">
                        Start date must be earlier than end date.
                    </div>
                )}
                <p className="initial-data-label">FROM - TO</p>
                <div className="time-range-box">
                    <div className="calendar-button-wrapper bottom-shadow-on-search">
                        <CalendarButton
                            isActive={activeButton === "start" && calendarOpen}
                            onClick={() => handleCalendarButtonClick()}
                        />
                    </div>
                    <div
                        onClick={() => handleCalendarButtonClick()}
                        className="date-range-info rounded-right-button left-calendar bottom-shadow-on-search"
                    >
                        <div className="date-range-info-inner rounded-right-button">
                            <div className="range-display highlight">
                                {startDate
                                    ? `${startDate.getDate().toString().padStart(2, "0")}/${
                                        (startDate.getMonth() + 1).toString().padStart(2, "0")
                                    }/${startDate.getFullYear().toString().slice(-2)}`
                                    : "start"}
                            </div>
                        </div>
                    </div>
                    <div className="calendar-button-wrapper bottom-shadow-on-search">
                        <CalendarButton
                            isActive={activeButton === "end" && calendarOpen}
                            onClick={() => handleCalendarButtonClick()}
                        />
                    </div>
                    <div
                        onClick={() => handleCalendarButtonClick()}
                        className="date-range-info rounded-right-button bottom-shadow-on-search"
                    >
                        <div className="date-range-info-inner rounded-right-button">
                            <div className="range-display highlight">
                                {endDate
                                    ? `${endDate.getDate().toString().padStart(2, "0")}/${
                                        (endDate.getMonth() + 1).toString().padStart(2, "0")
                                    }/${endDate.getFullYear().toString().slice(-2)}`
                                    : "end"}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="calendar-wrapper">
                {calendarOpen && (
                    <div ref={calendarRef}>
                        <CustomCalendar
                            isOpen={calendarOpen}
                            onClose={() => setCalendarOpen(false)}
                            onDateSelect={handleDateSelect}
                            selectedRange={startDate && endDate ? {start: startDate, end: endDate} : null}
                        />
                    </div>
                )}
            </div>
            <div className="counter-wrapper">
                {adultsError && (
                    <div className="error-detail">{adultsError}</div>
                )}
                <p className="initial-data-label">ADULTS</p>
                <Counter
                    value={values.adults}
                    onChange={handleChange("adults")}
                />
            </div>
            <div className="counter-wrapper">
                <p className="initial-data-label">CHILDREN</p>
                <CounterChildren
                    value={values.children}
                    onChange={handleChange("children")}
                />
            </div>
            <Navigation
                onPreviousClick={prevStep}
                onNextClick={proceedToNextStep}
                nextDisabled={!startDate || !endDate || values.adults <= 0}
            />
        </div>
    );
}

export default UserDataEntryStep;
