import React, {useState} from "react";
import SelectActivityButton from "../buttons/selectTagButton";
import './styles.css';
import '../../../commonStyles.css';

const Tag = ({id, icon, alt, onClick}) => {

    const [isPressed, setIsPressed] = useState(false);
       const handleClick = () => {
        setIsPressed(true);
        setTimeout(() => {
            setIsPressed(false);
            onClick(id); // Call the onClick after 300ms (animation time)
        }, 300); // Delay the callback to wait for the animation
    };

    return (
        <div className={`tag bottom-shadow ${isPressed ? 'selected' : ''}`} onClick={handleClick}>
            <img src={icon} alt={alt} className={`tag-icon ${isPressed ? 'highlight' : ''}`}/>
            <SelectActivityButton onClick={handleClick} isSelected={isPressed}/>
        </div>
    );
};

export default Tag;