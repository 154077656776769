import React from 'react';
import '../carousel/detailsPage/styles.css';
import {ReactComponent as TaxiImage} from "../../../images/icons/car-taxi-front.svg";
import {calculateDurationInMinutes, extractTime, extractDate} from "../../utils/calculationFunctions";

const TransferOfferDetails = ({details, detailsBack}) => {

    if (!details) {
        return <div className="error-message">No details available</div>;
    }

    return (
        <div className="offer-details">
            <div className="transfer-wrapper">
                <div className="transfer-wrapper-inner">
                    <div className="transfer-info-inner">
                        <div className="transfer-monitor highlight">
                            {details.segments.map((detail, index) => (
                                <React.Fragment key={index}>
                                    <div className="transfer-details-container">
                                        {/* Transfer departure from airport */}
                                        <div className="transfer-details-departure">
                                            <span className="airport-name">Airport</span>
                                            <span className="departure-time"> {extractTime(detail.start.dateTime)}
                                        </span>
                                            <span
                                                className={"transfer-date"}>{extractDate(detail.start.dateTime)}</span>
                                        </div>
                                        {/* Duration */}
                                        <div className="transfer-duration-container">
                                            <div className={"transfer-image"}><TaxiImage/></div>
                                            <p className="duration-text">
                                                -- {calculateDurationInMinutes(detail.start.dateTime, detail.end.dateTime) || 'N/A'} --&gt;
                                            </p>
                                        </div>

                                        {/* Arrival to accommodation */}
                                        <div className="transfer-details-arrival">
                                            <span className="accommodation-name">
                                                Hotel
                                            </span>
                                            <span className="arrival-time">
                                                {extractTime(detail.end.dateTime)}
                                            </span>

                                        </div>
                                    </div>
                                </React.Fragment>
                            ))}
                            <div className="separator-line"></div>
                            {detailsBack.segments.map((detail, index) => (
                                <React.Fragment key={index}>
                                    <div className="transfer-details-container">
                                        {/* Transfer departure from airport */}
                                        <div className="transfer-details-departure">
                                            <span className="airport-name">Hotel</span>
                                            <span className="departure-time"> {extractTime(detail.start.dateTime)}
                                        </span>
                                            <span
                                                className={"transfer-date"}>{extractDate(detail.start.dateTime)}
                                            </span>
                                        </div>
                                        {/* Duration */}
                                        <div className="transfer-duration-container">
                                            <div className={"transfer-image"}><TaxiImage/></div>
                                            <p className="duration-text">
                                                -- {calculateDurationInMinutes(detail.start.dateTime, detail.end.dateTime) || 'N/A'} --&gt;
                                            </p>
                                        </div>

                                        {/* Arrival to accommodation */}
                                        <div className="transfer-details-arrival">
                                            <span className="accommodation-name">
                                                Airport
                                            </span>
                                            <span className="arrival-time">
                                                {extractTime(detail.end.dateTime)}
                                            </span>
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransferOfferDetails;
